import { createSlice } from '@reduxjs/toolkit';
import { difference } from 'lodash';
import Shift from 'resources/Shift';

import { actionTypes as addTeamActions } from 'actions/addTeam';
import { actionTypes as employeeViewActionTypes } from 'actions/employeeView';
import { actionTypes as timeOffsActions } from 'actions/timeOff';

import { getObjectFromLocalStorage } from 'util/localStorageWrapper';

import RoleLaborTotals from './resources/RoleLaborTotals';
import Schedule from './resources/Schedule';
import {
  DRAWERS,
  EMPLOYEE_VIEW_FILTERS,
  FILTERS,
  SLICE_NAME,
  VIEW_BY,
  VIEW_BY_DAY,
} from './constants';
import { getInitialStateFromLS } from './helpers';

const setDrawer = (state, drawer, uxContext = {}) => {
  state.activeDrawer = drawer;
  state.drawerUxContext = uxContext;
};

const checkForJobUpdate = (state, { payload }) => {
  const { id, type } = payload.data.relationships.owner.data;
  if (type === 'job') {
    state.pendingJobLaborTotalsIds.push(id);
  }
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState: {
    currentDateRange: null,
    conflictsEnabled: false,
    viewType: 'employee',
    rangeType: 'week',
    searchTerm: '',
    hasPendingData: false,
    isJumpstartClosed: false,
    // This array contains IDs of Jobs that still require labor totals to be fetched
    pendingJobLaborTotalsIds: [],
    activeDrawer: null,
    employeeViewFilter: EMPLOYEE_VIEW_FILTERS.ALL,
    departmentId: null,
    notificationCenterMessage: null,
    notificationCenterType: null,
    customJobIds: [],
    isAvailabilitiesVisible: getInitialStateFromLS(
      FILTERS.IS_AVAILABILITIES_VISIBLE
    ),
    isTimeOffsVisible: getInitialStateFromLS(FILTERS.IS_TIME_OFFS_VISIBLE),
    isShiftsAtThisLocationOnly:
      getObjectFromLocalStorage(FILTERS.IS_SHIFTS_AT_THIS_LOCATION_ONLY) ||
      false,
    copyWeekModalIsOpen: false,
    sortByDay: getObjectFromLocalStorage(VIEW_BY_DAY) || VIEW_BY.firstName,
  },
  reducers: {
    toggleConflicts: state => {
      state.conflictsEnabled = !state.conflictsEnabled;
    },
    closeJumpstartDrawer: state => {
      state.isJumpstartClosed = true;
      setDrawer(state, null);
    },
    showJumpstartDrawer: (state, { payload: { uxContext } }) => {
      setDrawer(state, DRAWERS.jumpstart_drawer, uxContext);
    },
    resetJumpstartDrawer: state => {
      state.isJumpstartClosed = false;
    },
    showPublishDrawer: (state, { payload: { uxContext } }) => {
      setDrawer(state, DRAWERS.publish_drawer, uxContext);
    },
    showPrintScheduleDrawer: (state, { payload: { uxContext } }) => {
      setDrawer(state, DRAWERS.print_schedule_drawer, uxContext);
    },
    showTemplatesDrawer: (state, { payload: { uxContext } }) => {
      setDrawer(state, DRAWERS.templates_drawer, uxContext);
    },
    showSendToPartnerDrawer: (state, { payload: { uxContext } }) => {
      setDrawer(state, DRAWERS.send_to_partner_drawer, uxContext);
    },
    hideDrawer: state => {
      if (state.activeDrawer === DRAWERS.publish_drawer)
        state.departmentId = null;

      setDrawer(state, null);
    },
    openCopyWeekModal: state => {
      state.copyWeekModalIsOpen = true;
    },
    closeCopyWeekModal: state => {
      state.copyWeekModalIsOpen = false;
    },
    showNotification: (state, { payload: { message, type } }) => {
      state.notificationCenterMessage = message;
      state.notificationCenterType = type;
    },
    hideNotification: state => {
      state.notificationCenterMessage = null;
      state.notificationCenterType = null;
    },
    setCurrentDateRange: (state, { payload: currentDateRange }) => {
      state.currentDateRange = currentDateRange;
    },
    setViewType: (state, { payload: viewType }) => {
      state.viewType = viewType;
    },
    setRangeType: (state, { payload: rangeType }) => {
      state.rangeType = rangeType;
    },
    setEmployeeViewFilter: (state, { payload: employeeViewFilter }) => {
      state.employeeViewFilter = employeeViewFilter;
      state.conflictsEnabled = false;
    },
    toggleShiftForm: (state, { payload: isShiftFormOpen }) => {
      state.isShiftFormOpen = isShiftFormOpen;
    },
    setSearchTerm: (state, { payload: searchTerm }) => {
      state.searchTerm = searchTerm;
    },
    loadJobTotals: (state, { payload: pendingJobLaborTotalsIds }) => {
      state.pendingJobLaborTotalsIds = pendingJobLaborTotalsIds;
    },
    setCustomJobIds: (state, { payload: customJobIds }) => {
      state.customJobIds = customJobIds;
    },
    setSortByDay: (state, { payload: sortByDay }) => {
      state.sortByDay = sortByDay;
    },
    setDepartmentId: (state, { payload: { departmentId } }) => {
      state.departmentId = departmentId;
    },
    startCopyPaste: (state, { payload: shiftId }) => {
      state.copyPasteShift = shiftId;
    },
    stopCopyPaste: state => {
      state.copyPasteShift = null;
    },
    updatePendingJobLaborTotalsIds: (state, { payload: id }) => {
      if (state.pendingJobLaborTotalsIds.includes(id) === false) {
        state.pendingJobLaborTotalsIds.push(id);
      }
    },
    updateShowHideAvailabilities: (state, { payload: isChecked }) => {
      state.isAvailabilitiesVisible = isChecked;
    },
    updateShowHideTimeOffs: (state, { payload: isChecked }) => {
      state.isTimeOffsVisible = isChecked;
    },
    updateShowHideShiftsLocationsOnly: (state, { payload: isChecked }) => {
      state.isShiftsAtThisLocationOnly = isChecked;
    },
  },
  extraReducers: {
    [Schedule.resourceActionEvent('fetch')]: state => {
      state.hasPendingData = false;
    },
    [Shift.resourceActionEvent('create')]: checkForJobUpdate,
    [Shift.resourceActionEvent('update')]: checkForJobUpdate,
    // Refetch the whole schedule when contact information for an employee gets changed
    [addTeamActions.UPDATE_EMPLOYEES_SUCCESS]: state => {
      state.hasPendingData = true;
    },
    [timeOffsActions.ADD_TIME_OFF_SUCCESS]: state => {
      state.hasPendingData = true;
    },
    [addTeamActions.CREATE_EMPLOYEE_SUCCESS]: (state, action) => {
      const jobId = action.payload.jobs[0].id;
      // add ID of a new employee to pending job labor array
      // to fetch labor totals for this new Job
      state.pendingJobLaborTotalsIds.push(jobId);
      // Set pending data flag to true to refetch the whole schedule because of a new employee
      state.hasPendingData = true;
    },
    // Remove jobs from pending collection after their job labor totals get loaded
    [RoleLaborTotals.resourceActionEvent('post')]: (state, action) => {
      // meta.jobIds contains a list of all job IDs for which labor totals were requested,
      // even if those totals were not returned in the payload
      state.pendingJobLaborTotalsIds = difference(
        state.pendingJobLaborTotalsIds,
        action.payload.meta.jobIds
      );
    },
    [employeeViewActionTypes.TERMINATION_FORM_SUCCESS]: state => {
      state.hasPendingData = true;
    },
  },
});

export const { actions, reducer } = slice;
